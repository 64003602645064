import {Swiper, Pagination} from "swiper";
import { Fancybox } from "@fancyapps/ui";

Fancybox.bind("[data-fancybox]", {
    // Your custom options
});

const reviewsSlider = new Swiper('#works', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    modules: [Pagination],
    spaceBetween: 30,
    // If we need pagination
    pagination: {
        el: '#works .swiper-pagination',
        clickable: true
    },
    breakpoints: {
        200: {
            slidesPerView: 1
        },
        768: {
            slidesPerView: 2
        }
    }
});