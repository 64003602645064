import $ from 'jquery'

$(window).scroll(function(){
    var sticky = $('.header'),
        scroll = $(window).scrollTop();
    if (scroll >= 100) sticky.addClass('fixed');
    else sticky.removeClass('fixed');
});

$("header nav a").click(function(e) {
    e.preventDefault()
    let id = $(this).attr('href')

    $([document.documentElement, document.body]).animate({
        scrollTop: $(id).offset().top
    }, 200);

});

$(".mobile-menu nav a").click(function(e) {
    e.preventDefault()
    let id = $(this).attr('href')
    $('.mobile-menu').removeClass('active')
    $('body').removeClass('hidden')
    $([document.documentElement, document.body]).animate({
        scrollTop: $(id).offset().top
    }, 200);

});

$('.burger').click(function (){
   $('.mobile-menu').toggleClass('active')
   $('body').toggleClass('hidden')
});

$('.burger-close').click(function (){
   $('.mobile-menu').toggleClass('active')
    $('body').toggleClass('hidden')
});