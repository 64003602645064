import SmokeMachine from '@bijection/smoke'
import $ from 'jquery'

var canvas = document.getElementById('smoke')
var ctx = canvas.getContext('2d')
// canvas.width = '100%'
// canvas.height = 00


canvas.width = innerWidth
canvas.height = innerHeight

var party = SmokeMachine(ctx, [32, 140, 147])
// var party = smokemachine(ctx, [18, 16, 54])
party.start() // start animating
// party.setPreDrawCallback(function(dt){
//     party.addSmoke(innerWidth/2, innerHeight, 15)
//     canvas.width = innerWidth
//     canvas.height = innerHeight
// })

onmousemove = function (e) {
    var x = e.clientX
    var y = e.clientY
    var n = 1
    var t = Math.floor(Math.random() * 200) + 3800
    party.addsmoke(x, y, n, t)
}