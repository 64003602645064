import {Swiper, Pagination} from "swiper";


const reviewsSlider = new Swiper('#reviews', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    modules: [Pagination],
    spaceBetween: 30,
    // If we need pagination
    pagination: {
        el: '#reviews .swiper-pagination',
        clickable: true
    },
    breakpoints: {
        200: {
            slidesPerView: 1
        },
        1200: {
            slidesPerView: 2
        }
    }
});